<template>
  <div
    class="drop-area"
    :class="{ enter: isEnter }"
    @dragover.prevent
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @drop.prevent="onDropFile"
  >
    <v-icon x-large>{{ isEnter ? "mdi-cloud-upload-outline" : "mdi-cloud-upload" }}</v-icon>
    <p class="text-subtitle-1">ドラッグ&amp;ドロップでファイルをアップロードできます</p>
  </div>
</template>

<style scoped>
.drop-area {
  font-weight: bold;
  border: solid 3px rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.enter {
  border-style: dotted;
}
</style>

<script>
export default {
  name: "SimpleFileDrop",
  props: {
    multiple: { type: Boolean, default: false },
  },
  data: () => ({
    isEnter: false,
  }),
  computed: {
    isMultiple() {
      return this.multiple != null && this.multiple !== false;
    },
  },
  methods: {
    onDragEnter() {
      this.isEnter = true;
    },
    onDragLeave() {
      this.isEnter = false;
    },
    onDropFile(e) {
      if (this.isMultiple) {
        this.$emit("input", e.dataTransfer.files);
      } else {
        this.$emit("input", e.dataTransfer.files[0]);
      }
      this.isEnter = false;
    },
  },
};
</script>
